import { GET_SETTINGS } from "../services/store/listing.module";
export default {
	name: "settings-mixin",
	data() {
		return {
			settingsEndpoint: null,
			pageLoading: false,
		};
	},
	methods: {
		getSettings() {
			this.pageLoading = true;
			if (this.settingsEndpoint) {
				this.$store
					.dispatch(GET_SETTINGS, { url: this.settingsEndpoint })
					.then(() => {})
					.catch((error) => {
						console.error({ error });
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}
		},
	},
	mounted() {
		this.getSettings();
	},
};
