import {
	GET_LISTING,
	BULK_ACTION,
	UPDATE_TABLE,
	SET_SELECTED_ROWS,
	SET_SEARCH_MODEL,
	SET_SEARCH_TITLE,
	SET_SEARCH_BAR,
	SET_THEAD,
	BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { cloneDeep, orderBy /* , includes */, isEmpty, identity, pickBy } from "lodash";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import heightMixin from "@/core/mixins/height.mixin.js";
import objectPath from "object-path";
import SettingsMixin from "@/core/mixins/settings.mixin.js";
// import { GET_SETTINGS } from "../services/store/listing.module";

export default {
	mixins: [heightMixin, SettingsMixin],
	data() {
		return {
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			totalSelected: 0,
			productType: null,
			opportunity: null,
			filter_source: null,
			filter_user: 0,
			filter_reminder_date: [],
			paginationPageSize: 0,
			pageLoading: true,
			tempTheadShow: null,
			advanceSearch: false,
			filter: new Object(),
			btxQuery: new Object(),
			exportCurrentDialog: false,
			exportDialog: false,
			importDialog: false,
			status: "all",
			pageTitle: null,
			pageBreadcrumbs: null,
			endpoint: null,
			internal: false,
			relation_type: null,
			relation_type_id: 0,
			defaultFilter: new Object(),
			permissions: null,
			currentPermissions: null,
			sortColumn: {
				name: null,
				column: null,
				order: null,
			},
			settings_endpoint: "lead-setting",
		};
	},
	methods: {
		resetSearch() {
			//console.log(this.$router);
			let filterQuery = this.$route.query;

			this.$router
				.replace({
					query: {
						...filterQuery,
						/* status: this.status || "all",  Krishn*/
						t: new Date().getTime(),
						//...filterQuery
					},
				})
				.then(() => {
					this.btxQuery = new Object();
					this.status = this.endpoint == "seo" ? null : filterQuery.status || "all";
					(this.productType = null),
						(this.opportunity = null),
						(this.filter_source = null),
						(this.filter_user = 0),
						(this.filter_reminder_date = []);
					this.$store.dispatch(SET_SEARCH_MODEL, null);
				})
				.then(() => {
					this.getListing();
				});
		},
		doAction(key) {
			switch (key) {
				case "import":
					this.importDialog = true;
					break;
				case "export":
					this.exportDialog = true;
					break;
				case "export_current_view":
					this.exportCurrentDialog = true;
					break;
				case "mark_as_read":
					this.doBulkAction("mark_as_read", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_not_contacted":
					this.doBulkAction("mark_as_not_contacted", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_follow_up":
					this.doBulkAction("mark_as_follow_up", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_meeting":
					this.doBulkAction("mark_as_meeting", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_proposal":
					this.doBulkAction("mark_as_proposal", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_quotation":
					this.doBulkAction("mark_as_quotation", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_accept":
					this.doBulkAction("mark_as_accept", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_reject":
					this.doBulkAction("mark_as_reject", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_on_hold":
					this.doBulkAction("mark_as_on_hold", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_resend_quote":
					this.doBulkAction("mark_as_resend_quote", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_active":
					this.doBulkAction("mark_as_active", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_inactive":
					this.doBulkAction("mark_as_inactive", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_completed":
					this.doBulkAction("mark_as_completed", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_cancelled":
					this.doBulkAction("mark_as_cancelled", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_overdue":
					this.doBulkAction("mark_as_overdue", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_upcoming":
					this.doBulkAction("mark_as_upcoming", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_approved":
					this.doBulkAction("mark_as_approved", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_pending":
					this.doBulkAction("mark_as_pending", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_rejected":
					this.doBulkAction("mark_as_rejected", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_task_progress":
					this.doBulkAction("mark_task_progress", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_task_completed":
					this.doBulkAction("mark_task_completed", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_task_pending":
					this.doBulkAction("mark_task_pending", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_holiday_active":
					this.doBulkAction("mark_holiday_active", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_holiday_inactive":
					this.doBulkAction("mark_holiday_inactive", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_inreview":
					this.doBulkAction("mark_as_inreview", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_claim_approved":
					this.doBulkAction("mark_claim_approved", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_claim_pending":
					this.doBulkAction("mark_claim_pending", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_claim_rejected":
					this.doBulkAction("mark_claim_rejected", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "delete_rows":
					this.doBulkAction("delete_rows", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "refresh":
					this.getListing();
					break;
			}
		},
		filterRows() {
			this.$router
				.replace({
					query: {
						...this.$route.query,
						...this.btxQuery,
						page: this.currentPage,
						status: this.status,
						search: this.listingSearch || undefined,
						t: new Date().getTime(),
					},
				})
				.then(() => {
					this.getListing();
					this.getSettings();
				});
		},
		doAdvanceSearch() {
			const btxQuery = cloneDeep(pickBy(this.btxQuery, identity));
			this.$router
				.replace({
					query: {
						...btxQuery,
						status: this.endpoint == "seo" ? null : this.status || "all",
						search: undefined,
						t: new Date().getTime(),
					},
				})
				.then(() => {
					this.getListing();
					this.advanceSearch = false;
				});
		},
		doBulkAction(type, url) {
			const _this = this;
			_this.pageLoading = true;
			return new Promise((resolve, reject) => {
				_this.$store
					.dispatch(BULK_ACTION, {
						type,
						url,
						selected: _this.selected,
					})
					.then(() => {
						resolve(true);
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
						this.$store.commit(SET_SELECTED_ROWS, []);
					});
			});
		},
		clearSelection() {
			this.$store.commit(SET_SELECTED_ROWS, []);
		},
		// getSettings() {
		// 	this.pageLoading = true;
		// 	this.$store
		// 		.dispatch(GET_SETTINGS,{ url: this.endpoint + '-setting' })
		// 		.then(() => {})
		// 		.catch((error) => {
		// 			console.error({ error });
		// 		})
		// 		.finally(() => {
		// 			this.pageLoading = false;
		// 		});
		// },
		getListing(page = undefined) {
			this.pageLoading = true;
			const relational = new Object({});

			if (this.internal) {
				relational["internal"] = 1;
				relational["type"] = this.relation_type;
				relational["type-id"] = this.relation_type_id;
			}

			const {
				query: { sort },
			} = this.$route;

			if (sort) {
				const [name, column, order] = sort.split(":");
				this.sortColumn = { name, column, order };
			}

			const filter = new Object({
				status:
					this.endpoint == "seo" ||
					this.endpoint == "domain" ||
					this.endpoint == "ssls" ||
					(this.endpoint && this.endpoint.includes("list-backlink"))
						? null
						: this.status,
				related_id:
					this.endpoint == "invoice" || this.endpoint == "contract" || this.endpoint == "project"
						? this.relatedId
						: null,
				internal_id: this.endpoint == "invoice" ? this.internalId : null,
				...this.defaultFilter,
				...this.$route.query,
				...relational,
				tz: new Date().getTime(),
				date:
					this.filter_reminder_date &&
					this.filter_reminder_date.length &&
					this.filter_reminder_date.length > 0
						? this.filter_reminder_date
						: this.$route.query.date,
				product_type: this.productType ? this.productType : null,
				opportunity: this.opportunity || null,
				users: this.filter_user || null,
				source: this.filter_source || null,
				page: page || this.$route.query.page || null,
				search: this.listingSearch || undefined,
				sort_column: this.sortColumn.column,
				sort_order: this.sortColumn.order,
				/* 	currentUser: this.currentUser.id || null, */
				// per_page: 2
			});
			delete filter.sort;

			this.$store
				.dispatch(GET_LISTING, { url: this.endpoint, filter })
				.then(() => {
					setTimeout(() => {
						this.calculateHeight();
					}, 500);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.clearSelection();
					this.pageLoading = false;
				});
		},
		updateTableVisiblity(type, key) {
			let tHeadClone = cloneDeep(this.thead);
			let changedKey = key;
			let indexInResultThead = tHeadClone.findIndex((col) => col.key == changedKey);

			console.log({ tt: tHeadClone[indexInResultThead] });

			if (tHeadClone[indexInResultThead] && "visible" in tHeadClone[indexInResultThead]) {
				tHeadClone[indexInResultThead].visible = !tHeadClone[indexInResultThead].visible;
			}
			tHeadClone = orderBy(tHeadClone, ["order"], ["asc"]);
			this.$store.commit(SET_THEAD, tHeadClone);
			this.updateTable(type);
		},
		updateTable(type) {
			const params = new Object({
				tables: this.thead,
			});
			this.pageLoading = true;
			this.$store
				.dispatch(UPDATE_TABLE, { type, params })
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getFilters() {
			const query = this.$route.query;
			for (const field of this.listingFilter) {
				this.btxQuery[field.model] = query[field.model];
			}
			this.btxQuery = cloneDeep(pickBy(this.btxQuery, identity));
		},
		search_Enabled() {
			const btxQuery = cloneDeep(pickBy(this.btxQuery, identity));
			if (isEmpty(btxQuery) && !this.listingSearch) {
				return false;
			}
			return true;
		},
	},
	watch: {
		listingFilter: {
			deep: true,
			handler() {
				this.getFilters();
			},
		},
		listingSearch(param) {
			let filterQuery = this.$route.query;
			this.btxQuery = new Object();
			this.$router
				.replace({
					query: {
						...filterQuery,
						page: 1,
						/* viewType: this.viewType, */
						search: param || undefined,
						t: new Date().getTime(),
					},
					/* 	query: {
						status: this.status || "all",
						search: param || undefined,
						t: new Date().getTime(),
					}, */
				})
				.then(() => {
					this.getListing();
				});
		},
		currentUserPermissions: {
			deep: true,
			immediate: true,
			handler(permission) {
				this.currentPermissions = permission;
			},
		},
		_pageLoading: {
			handler(param) {
				this.pageLoading = param;
			},
		},
		selected() {
			this.calculateHeight();
		},
	},
	mounted() {
		const { query } = this.$route;
		/* Top search bar will trigger getListing() */
		if (
			Object.hasOwn(query, "search") == false ||
			(Object.hasOwn(query, "search") && !query?.search)
		) {
			this.getListing();
		}

		if (!this.internal) {
			this.$store.dispatch(SET_SEARCH_BAR, true);
			this.$store.dispatch(SET_SEARCH_TITLE, this.pageTitle);
			this.$store.dispatch(SET_BREADCRUMB, this.pageBreadcrumbs || []);
		}
	},
	beforeDestroy() {
		this.$store.dispatch(BEFORE_DESTROY);
		if (!this.internal) {
			this.$store.dispatch(SET_BREADCRUMB, []);
		}
		this.$store.dispatch(SET_SEARCH_MODEL, null);
	},
	computed: {
		...mapGetters([
			"searchEnabled",
			"listingAction",
			"listingBulkAction",
			"listingStatus",
			"listingFilter",
			"listingSearch",
			"currentPage",
			"currentUser",
			"thead",
			"_pageLoading",
			"selected",
			"theadShow",
			"total",
			"total_rows",
			"total_active",
			"total_expire",
			"total_expire_1_month",
			"getProfile",
			"sortedThead",
			"currentUserPermissions",
		]),
		draggableThead: {
			set(val) {
				let result = cloneDeep(val);
				for (let i = 0; i < result.length; i++) {
					if (!result[i].fixed) {
						result[i].order = i;
					}
				}
				result = orderBy(result, ["order"], ["asc"]);
				this.$store.commit(SET_THEAD, result);
			},
			get() {
				return orderBy(this.thead, ["order"], ["asc"]) || [];
			},
		},
		draggableTheadShow: {
			set(val) {
				console.log({ val });
				this.tempTheadShow = cloneDeep(val);
			},
			get() {
				return this.tempTheadShow || this.theadShow || [];
			},
		},
		allowedSortedThead: {
			get() {
				/* 	Permission and users_type 
					both must allow to show Actions
					
					Note :- Use [ allowedSortedThead ] to iterate headers and 
							permissions: ['seo:update', 'seo:delete']
							use this to check for permission and enable and disable the columns
				*/

				let _sortedThead = cloneDeep(this.sortedThead);
				let type = objectPath.get(this.currentUser, "users_type");
				let role_id = objectPath.get(this.currentUser, "role");
				let isMaster = objectPath.get(this.currentUser, "is_master");
				let permission = this.currentPermissions ? this.currentPermissions : [];

				if (this.permissions && Array.isArray(this.permissions)) {
					let update = permission.includes(this.permissions[0]);
					let _delete = permission.includes(this.permissions[1]);
					if (
						((type == "sales" || type == "accounts" || (!update && !_delete)) && isMaster != 1) ||
						role_id == 9 /* ATL */ ||
						role_id == 10 /* ON Page */ ||
						role_id == 11 /* OFF Page */
					) {
						let idxToRestrict = _sortedThead.findIndex((v) => v.key == "action");
						_sortedThead.splice(idxToRestrict, 1);
					} else if (type == "seo") {
						let idxToRestrict = _sortedThead.findIndex((v) => v.key == "project_details");
						_sortedThead.splice(idxToRestrict, 1);
					}
				}

				/* this.$store.commit(SET_THEAD, _sortedThead); */

				return _sortedThead;
			},
		},
	},
	components: {
		FilterDialog: () => import("@/view/components/FilterTemplate.vue"),
		ImportDialog: () => import("@/view/components/ImportDialog.vue"),
		ExportDialog: () => import("@/view/components/ExportDialog.vue"),
		Draggable: () => import("vuedraggable"),
		SearchCriteria: () => import("@/view/components/SearchCriteria.vue"),
		Table: () => import("@/view/components/Table"),
	},
};
