<template>
	<!-- <v-sheet class="customer leads-container" style="height: calc(100vh - 88px)"> -->
	<div>
		<v-layout align-content-start>
			<v-flex xs6 sm2 class="py-0">
				<!-- mdi-account-multiple-check , mdi-account-check , mdi-account-cancel -->
				<v-progress-circular
					indeterminate
					color="#0d47a1"
					size="48"
					v-if="pageLoading"
				></v-progress-circular>
				<v-select
					v-else
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					solo
					@change="statusFilterRows"
					rounded
					style="max-width: 200px !important"
					:menu-props="{ offsetY: true, contentClass: 'rounded-lg border' }"
				>
					<template #prepend-inner>
						<v-icon class="mr-1" :color="statusColors[status]">{{ statusIcons[status] }}</v-icon>
					</template>
					<template #item="{ item }">
						<div class="d-flex fw-600 py-1 align-center justify-space-between w-100">
							<div>
								{{ item.text }}
							</div>
							<div>
								<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
									<span class="white--text">{{ item.status_count }}</span>
								</v-avatar>
							</div>
						</div>
					</template>
				</v-select>
			</v-flex>

			<v-flex class="py-0 my-auto d-flex text-right listing-right justify-content-end">
				<template v-if="selected.length > 0">
					<v-menu
						:key="tableManageKey + 2"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0" :key="tableManageKey + 1">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item
									link
									:key="i"
									v-on:click="doAction(item.value), (tableManageKey = +new Date())"
								>
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<!-- <v-flex class="d-flex justify-content-end mr-1"> -->
					<!-- <div class="mr-2 fw-600 my-auto">Filter By</div>
						<div class="filterTagSelect ml-3">
							<v-autocomplete
								v-model="filter_user"
								:items="userItems"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-filter"
								outlined
								hide-details
								class="filterSelect"
								content-class="filterSelectContent"
							>
							</v-autocomplete>
						</div> -->
					<!-- </v-flex> -->
					<v-flex sm12 class="d-flex align-center justify-end">
						<div class="fw-600">Filter by</div>
						<div class="ml-2 filterTagSelect inline-filter">
							<v-autocomplete
								:items="roles"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								clearable
								hide-details
								v-model="filter.role"
								item-text="role"
								item-value="id"
								placeholder="Select Role"
								style="width: 200px !important"
								@change="statusFilterRows"
								noStyleList
							>
								<template #no-data> No role found </template>
								<template #li="{ item }">
									<div class="fw-500 py-1 text-capitalize">
										{{ item.role }}
									</div>
								</template>
							</v-autocomplete>
						</div>
						<div class="ml-1">
							<v-btn
								depressed
								color="white"
								class="red darken-4"
								tile
								text
								small
								style="height: 26px; min-width: 0px !important"
								@click="clearFliter"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>
					</v-flex>
					<v-btn
						depressed
						tile
						color="red darken-4 ml-2"
						class="text-white"
						@click="createUser"
						:disabled="pageLoading"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<!-- 	<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="routeSetting()"
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn> -->

					<!-- <v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="viewType = !viewType"
					>
						<v-icon v-if="viewType">mdi-view-list</v-icon>
						<v-icon v-else>mdi-grid</v-icon>
					</v-btn> -->

					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						@click="drawerFilter = true"
						x-small
						min-height="26px"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn>
					<v-menu
						:key="tableManageKey + 3"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }" v-if="false">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
								x-small
								min-height="26px"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list class="py-0" :key="tableManageKey">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title v-on:click="referess"
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
								x-small
								min-height="26px"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							@change="updateTable('user')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										@change="updateTableVisiblity('user', cols.key)"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-flex>
		</v-layout>

		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="User #, Display Name, Email and Phone no. "
			@advance-search="advanceSearch = true"
			@close-search="resetSearch"
		></SearchCriteria>

		<template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="mb-2 btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>
					<ul>
						<li>
							Advance Filter User #, Display Name, Email and Phone no.
							<span class="search-text">{{}}</span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template>
			<Table
				type="user"
				delete-endpoint="user/"
				detail-route="user-detail"
				is-drawer-detail
				@open-drawer="open_drawer($event)"
				@reload:content="filterRows"
				:status-filter-data="statusFilterData"
			></Table>
		</template>

		<FilterDialog
			title="Users"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			@do-search="doAdvanceSearch"
			@close="advanceSearch = false"
		>
		</FilterDialog>
		<AdvanceFilter
			v-if="drawerFilter"
			@addvanceFilterData="dataFilter"
			:advanceSearch="drawerFilter"
			@close="drawerFilter = false"
			search-endpoint="all-users"
			search-title="User"
			:filters="userFilters"
		></AdvanceFilter>

		<AddUser
			v-if="create_user"
			:drawer="create_user"
			:title-status="title"
			v-on:close="create_user = false"
			:user-data="editUser"
			v-on:refreshUserList="getListing"
			:user-type="userType"
		></AddUser>
		<!-- </v-sheet> -->
	</div>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
//import CustomerKanban from "@/view/module/contacts/customer/CustomerKanban";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_SELECTED_ROWS,
} from "@/core/services/store/listing.module";
import { GET, QUERY } from "@/core/services/store/request.module";
import { mapGetters } from "vuex";
import objectPath from "object-path";
import { getCurrentUser } from "@/core/services/jwt.service";

export default {
	name: "user-listing",
	title: "Listing Customer",
	mixins: [ListingMixin],
	data() {
		return {
			tableManageKey: +new Date(),
			skipBottomHeight: 72,
			drawerFilter: false,
			userItems: ["Everyone"],
			filter_user: null /* "Everyone" */,
			viewType: true,
			addvanceFilterAarry: [],
			pageTitle: "Users",
			pageBreadcrumbs: [
				{ text: "User", disabled: true },
				{ text: "User", disabled: true },
			],
			endpoint: "all-users",
			create_user: false,
			title: null,
			userStatus: [],
			editUser: {},
			statusFilterData: [],
			userType: null,
			roles: [],
			filter: {
				role: null,
			},
			userFilters: [],

			statusColors: {
				all: "primary",
				1: "green",
				2: "red",
			},
			statusIcons: {
				all: "mdi-account-group",
				1: "mdi-account-multiple-check",
				2: "mdi-account-multiple-minus",
			},
		};
	},
	methods: {
		referess() {
			window.location.reload();
		},
		clearFliter() {
			// this.status = "all";
			this.filter = {
				role: null,
			};
			this.statusFilterRows();
		},
		createUser() {
			this.title = "Create";
			this.editUser = {};
			this.create_user = true;
		},
		open_drawer(id) {
			this.editUser = {};
			this.title = "Update";
			this.create_user = true;
			this.$store
				.dispatch(GET, { url: `single-user/${id}` })
				.then((data) => {
					this.editUser = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		dataFilter(value) {
			this.addvanceFilterAarry = value;
		},
		getUserStatus() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, { url: "user-setting" })
				.then((data) => {
					if (data && Object.keys(data).length) {
						this.userStatus = data.status;
						const LOGIN_USER = getCurrentUser();
						const ROLES = data.role;
						if (LOGIN_USER.is_master == 1) {
							this.roles = ROLES;
						} else {
							const FILTERED_ROLES = ROLES.filter((role) => role.role_type == LOGIN_USER.users_type);
							this.roles = FILTERED_ROLES;
						}
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		statusFilterRows() {
			this.allFiltersStatus();
			this.pageLoading = true;
			const PAYLOAD = {
				status: this.status || "all",
				role: this.filter.role || null,
			};
			this.$store
				.dispatch(QUERY, {
					url: `all-users`,
					data: PAYLOAD,
				})
				.then((data) => {
					if (data && Object.keys(data).length) {
						this.$store.commit(SET_TBODY, data.tbody);
						this.$store.commit(SET_CURRENT_PAGE, data.current_page);
						this.$store.commit(SET_TOTAL_PAGE, data.total_page);
						this.$store.commit(SET_SHOWING_STRING, data.showing_string);
						this.$store.commit(SET_STATUS, data.status);
						this.$store.commit(SET_SELECTED_ROWS, []);
						this.addvanceFilterAarry = [];
						this.userFilters = data.filter;
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus() {
			const PAYLOAD = {
				status: this.status || "all",
				role: this.filter.role || null,
			};
			this.$router.push({
				name: "users",
				query: PAYLOAD,
			});
		},
		/* routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "customer",
				},
			});
		}, */
	},
	components: {
		//CustomerKanban,
		AdvanceFilter: () => import("@/view/pages/leads/AdvanceFilter"),
		AddUser: () => import("@/view/module/users/AddUser"),
	},
	watch: {
		getProfile: {
			deep: true,
			immediate: true,
			handler(profile) {
				let hasKey = objectPath.has(profile, "users_type");
				let getValue = objectPath.get(profile, "users_type");
				if (hasKey && getValue) {
					this.userType = getValue;
				}
			},
		},
	},
	computed: {
		...mapGetters(["getProfile", "listingSearch"]),
	},
	beforeMount() {
		let query = this.$route.query;
		this.status = query.status || "all";
	},
	mounted() {
		this.getUserStatus();
	},
};
</script>
